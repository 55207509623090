@import "functions";

@mixin reset-bounding {
  padding: 0;
  margin: 0;
}

@mixin reset-list {
  @include reset-bounding;
  list-style: none;
}

@mixin reset-link {
  text-decoration: none;
  color: inherit;
}

@mixin reset-input {
  background-color: transparent;
  border: none;
  outline: none;
}

@mixin reset-btn {
  @include reset-bounding;
  @include reset-input();

  &:enabled {
    cursor: pointer;
  }
}

@mixin absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin absolute-center-vertical {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin absolute-fill {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

@mixin text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin cheelee-gradient-border($gradient: button-background(), $radius: 100px, $animated: false) {
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: $radius;
    border: 2px solid transparent;
    background: $gradient border-box;
    mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
    z-index: 1;
    pointer-events: none;

    @if $animated == true {
      animation: animated-gradient 2s ease-in-out alternate infinite;
      background-size: 200%;
    } else {
      animation: unset;
    }
  }
}

@keyframes animated-gradient {
  from {
    background-position: 0 50%;
  }

  to {
    background-position: 100% 50%;
  }
}

@mixin flexbox(
  $direction: row,
  $placeContent: null,
  $placeItems: null,
  $wrap: nowrap,
  $shrink: 0,
  $grow: 0,
  $ac: null,
  $jc: null,
  $ai: null,
  $ji: null) {

  display: flex;
  flex-direction: $direction;

  @if $placeContent == null {
    @if $ac   { align-content: $ac; }
    @if $jc { justify-content: $jc; }
  } @else {
    place-content: $placeContent;
  }

  @if $placeItems == null {
    @if $ai   { align-items: $ai; }
    @if $ji { justify-items: $ji; }
  } @else {
    place-items: $placeItems;
  }

  flex-wrap: $wrap;
  flex-shrink: $shrink;
  flex-grow: $grow;
}

@mixin breakpoint($breakpoint) {
  @if $breakpoint == mobile {
    @media only screen and (max-width: 574px) {
      @content;
    }
  }
  @else if $breakpoint == tablet {
    @media only screen and (min-width: 575px) and (max-width: 1091px) {
      @content;
    }
  }
  @else if $breakpoint == desktop {
    @media only screen and (min-width: 1092px) {
      @content;
    }
  }
  @else {
    @warn 'Invalid breakpoint value: #{$breakpoint}';
  }
}
