@import "color-palette";

:root {
  // Generated variable example: --color-red-140: #2F0404;
  @each $color-name, $palette in $color-palette {
    @each $key, $value in $palette {
      $key-name: "--color-" + $color-name + "-" + $key;
      #{$key-name}: #{$value};
    }
  }
}
