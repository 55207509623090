@import "mixins";
@import "effects";
@import "functions";
@import "variables";

$transition-duration: .15s;
$color-overlay: rgba(0, 0, 0, 0.72);
$dialog-position-top: 50%;

@import "node_modules/ngx-smart-modal/styles/ngx-smart-modal";

.overlay {
	overflow-y: hidden;
	transition: .2s ease-in-out;

	&.nsm-overlay-open {
		backdrop-filter: blur(1px);
	}
}

.cheelee-dialog {
	@include flexbox($ai: center, $jc: center);
	height: 100%;
	transition: visibility .25s;
	padding: 24px;

	@include breakpoint(mobile) {
		height: 100%;
		margin: 0;
		max-width: unset;
	}

	&.mobile-fullscreen {
		padding: 0;

		.nsm-content {
			@include breakpoint(mobile) {
				width: 100%;
				height: 100%;
				border-radius: 0;
			}
		}
	}

	.nsm-content {
		border-radius: 32px;
		margin: 0;
		padding: 0;
		background: background-color("dialog");
    box-shadow: 0 4px 4px 0 #00000040;

    &.nsm-body {
			overflow: auto;
		}
	}
}

.cheelee-curtain {
	$animation-duration: .25s;

	@include flexbox($ai: center, $jc: flex-end, $direction: column);
	position: fixed;
	height: 100%;
	width: 100%;
	max-width: 100%;
	visibility: hidden;
	pointer-events: none;
	transform: translate3d(0, 100%, 0);
	transition: transform $animation-duration ease-in-out,opacity $animation-duration ease-in-out, visibility $animation-duration;

	&.nsm-dialog-open {
		visibility: visible;
		transform: translate3d(0, 0, 0);
  }

	&.nsm-dialog-close {
		transform: translate3d(0, 100%, 0);
	}

	.nsm-content {
		width: 100%;
		border-radius: $curtain-border-radius;
		background: transparent;
		box-shadow: none;
		pointer-events: none;
		margin: 0;
		padding: 0;
	}

	.nsm-body {
		height: 100dvh;
	}
}

.cheelee-confirm-modal {
	.nsm-content {
		padding: 0;
		background: transparent;
		box-shadow: none;
		align-items: center;
		width: 100dvw;
		min-width: 280px;
		margin-top: unset;
	}

	.nsm-body {
		border-radius: 24px;
		align-items: center;
		min-width: 280px;
	}
}

.fade-animation {
	visibility: hidden;
	transition: visibility .1s;

	&.nsm-dialog-open {
		visibility: visible;
		animation: fade-in .2s ease-in-out forwards;
	}

	&.nsm-dialog-close {
		animation: fade-out .1s ease-out forwards;
	}
}
