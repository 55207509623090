@import "variables";
@import "mixins";

.main-grid {
  height: var(--feed-height-mobile);

  @media (min-width: $breakPointTabletMin) {
  }

  @media (min-width: $breakPointDesktopMin) {
    display: grid;
    gap: 20px;
    grid-template-columns: var(--feed-left-width) var(--feed-width) var(--feed-right-width);
    height: var(--feed-height);
    padding-top: var(--feed-padding-top);
  }

  &.main-grid--tab-bar {
    height: var(--feed-height-mobile-with-tab-bar);
  }
}

.main-grid__center {
  height: var(--feed-height-mobile);
  @media (min-width: $breakPointDesktopMin) {
    height: var(--feed-height);
  }
}

.main-grid--tab-bar {
  .content,
  .main-grid__center {
    height: var(--feed-height-mobile-with-tab-bar);
  }
}

.main-grid__sticky {
  position: sticky;
  top: 84px;
  display: block;
  width: 100%;
  max-height: 100%;
  height: var(--feed-height-mobile);
  @media (min-width: $breakPointDesktopMin) {
    height: var(--feed-height);
  }
}

::ng-deep  .p-toast-message {
  margin-top: 8px;
}

.message__top {
  margin-bottom: 12px;
}
