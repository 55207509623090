@import "mixins";

cheelee-share {
  width: 100dvw;
  height: 100dvh;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
}

.share {
  margin-top: 8px;
  &.dialog {
    padding: 20px;
  }
}

.share__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  margin-bottom: 16px;
}

.share__list {
  @include flexbox($jc: center, $wrap: wrap);
  margin: 0;
  padding: 0;
  list-style: none;
}

.share__icon {
  @include flexbox($ai: center, $jc: center);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #E3E5E8;
  background: #100128;
  margin: 0 auto 8px;

  svg {
    width: 28px;
    height: 28px;
  }
}

.share__text {
  @include flexbox($jc: space-around);
  position: relative;
  left: 0;
  right: 0;
  font-size: 11px;
}

.share__item {
  position: relative;
  flex: 0 0 10%;
  margin: 0 8px 12px;
  cursor: pointer;

  button {
    width: 52px;
  }

  &--link {
    .share__icon {
      background: #7156FB;
    }

  }

  &--vkontakte {
    .share__icon {
      background: #4680c2;
    }

  }

  &--facebook {
    .share__icon {
      background: #3b5998;
    }

  }

  &--telegram {
    .share__icon {
      background: #64a9dc;
    }

  }

  &--twitter {
    .share__icon {
      background: #00aced;
    }

  }

  &--viber {
    .share__icon {
      background: #7b519d;
    }

  }

  &--whatsapp {
    .share__icon {
      background: #65bc54;
    }

  }

  &--linkedin {
    .share__icon {
      background: #0083be;
    }

  }
}


