@import "variables";
@import "mixins";
@import "functions";
@import "effects";

*, *:before, *:after {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
  -webkit-focus-ring-color: rgba(255, 255, 255, 0) !important;
}

html {
  overflow: hidden;
  width: 100%;
}

body {
  position: fixed;
  overflow-y: scroll;
  height: 100dvh;
  width: 100dvw;
  -webkit-overflow-scrolling: touch;
  font-family: $font-family-primary;
  font-size: $font-size-primary;
  line-height: $line-height-primary;
  font-weight: $font-weight-primary;
  letter-spacing: 0.02em;

  @include breakpoint(desktop) {
    position: unset;
    overflow-y: auto;
  }

  &[data-theme="dark"] {
    background-color: background-color("primary");
    color: $white-color;
  }

  &[data-theme="light"] {
    //  For the future
  }
}

input, text-area {
  caret-color: text-color("interactive-primary");

  &:invalid {
    caret-color: text-color("negative");
  }
}

input, button {
  font-family: inherit;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
}

button {
  @include reset-btn();
  transition: all .1s;

  &:hover:enabled {
    filter: brightness(115%) !important;
  }

  &:active:enabled {
    filter: brightness(90%) !important;
  }

  &:disabled {
    opacity: .5;
    cursor: not-allowed;
  }
}

a {
  color: text-color("interactive-primary");
  text-decoration: none;
  cursor: pointer;
}

svg {
  display: block;
}

label {
  color: $white-color;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.24px;
}

//main {
//  @include breakpoint(desktop) {
//    @include flexbox($ai: center);
//    position: relative;
//    max-width: 56dvh;
//    margin: 0 auto;
//    //padding-top: $header-height;
//  }
//}

/* Change Autocomplete styles */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: $white-color;
  -webkit-box-shadow: none;
  transition: background-color 5000s ease-in-out 0s;
}

@keyframes app-start {
  from {
    opacity: 0;
    transform: scale(.1);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}
