@import "variables";
@import "functions";

[data-theme="light"] {
  --header-background-color: #140132;
  --main-background-color: #e5e8ea;
  --main-content-text-color: #17191C;
}

[data-theme="dark"] {
  --header-background-color: #e5e8ea;
  --main-background-color: background-color();
  --main-content-text-color: $white-color;
}
