.market {
  height: 100%;

  &__button-container {
    position: relative;
    padding: 16px;

    &::after {
      position: absolute;
      content: "";
      display: block;
      width: 100%;
      height: 20px;
      left: 0;
      top: -20px;
      background: linear-gradient(to top, #1A0736, transparent);
    }
  }
}
