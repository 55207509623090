body {
  --header-height: 65px;
  --tab-bar-height: 56px;
  --feed-padding-top: calc(var(--header-height) + 20px);
  --feed-height: calc(100dvh - var(--feed-padding-top));
  --feed-height-mobile: 100dvh;
  --feed-height-mobile-with-tab-bar: calc(100dvh - var(--tab-bar-height));
  --feed-width: calc(var(--feed-height) * .56);
  --feed-left-width: 280px;
  --feed-right-width: 280px;
  --feed-main-grid-width: calc(var(--feed-width) + var(--feed-right-width) + var(--feed-left-width) + 2 * 20px);
}

$color-text-primary: #140132;

$color-btn-bg-primary: #7156FB;
$color-btn-bg-primary-hover: #ffffff;
$color-btn-bg-primary-active: #ffffff;
$color-btn-text-primary: #ffffff;
$color-btn-text-primary-hover: #140132;
$color-btn-text-primary-active: #140132;

$font-family-primary: "Montserrat", sans-serif;
$font-size-primary: 12px;
$font-weight-primary: 500;
$line-height-primary: 14px;

$breakPointMobileMin: 0px;
$breakPointMobileMax: 575px;
$breakPointTabletMin: 992px;
$breakPointDesktopMin: 1092px;

$containerMaxWidthDesktop: 1092px;
$containerPaddingXMobile: 16px;
$containerPaddingXDesktop: 16px;

$font-btn-size: 16px;
$line-height-btn: 16px;

$border-radius-btn: 12px;
$border-btn: 1px solid transparent;
$border-btn-hover: 1px solid #17191C;
$border-btn-active: 1px solid #E3E5E8;
$min-width-btn: 120px;
$min-height-btn: 40px;

$backgroundDarkPrimary: #100128;
$backgroundDarkSecondary: #231252;

$textColorDefault: #FCFCFD;
$textColorSecondary: #5E636E;

$borderColorError: #E61515;
$backgroundError: #E61515;

$black-color: #000;
$white-color: #FFFFFF;
$red-color: #EE4444;
$blue-color: #AB9BFD;
$dark-blue-color: var(--color-elderberry-50);
$grey-color: #757C8A;

$header-height: 56px;
$tab-bar-height: 56px;

$curtain-border-radius: 28px 28px 0 0;

$gradients: (
  "yellow": linear-gradient(98.42deg, #F09F00 0%, #FFD335 100%),
  "orange": linear-gradient(107.46deg, #E3061A 0%, #FA7C07 100%),
  "pink": linear-gradient(106.29deg, #FA05F1 0%, #FC69F7 103.22%),
  "lilac": linear-gradient(134.99deg, #D204CB 0%, #7156FB 100%),
  "violet": linear-gradient(90deg, #5C48D7 0%, #C056FB 100%),
  "iridescent": linear-gradient(81.59deg, #AB38BE 0%, #2E1194 50.52%, #00E4AA 100%),
  "navy": linear-gradient(98.42deg, #6D34E6 0%, #00CDE9 100%),
  "blue": linear-gradient(8.05deg, #22479A 5.56%, #00E4AA 90.19%),
  "teal": linear-gradient(90deg, #0077CD 0%, #00E4AA 100%),
  "pine": linear-gradient(98.42deg, #108564 0%, #55D1A4 100%),
  "aquamarine": linear-gradient(109.56deg, #02FFF2 0%, #01FFC2 100%),
  "radial-usdt": radial-gradient(84.37% 71.87% at 12.5% 21.87%, #41BC90 6.25%, #0F8563 72.46%),
  "radial-bnb": radial-gradient(84.37% 71.87% at 12.5% 21.87%, #FFD757 6.25%, #ECB60D 72.46%),
  "tik-tok": linear-gradient(142.72deg, #FF0B53 0%, #DC00DC 36.03%, #7156FB 61.34%, #00CDF4 99.48%),
  "twitter": linear-gradient(293.24deg, #7156FB 15.02%, #00CDF4 84.61%),
  "lilac-transparent": linear-gradient(90deg, rgba(20, 1, 50, 0) 0%, rgba(20, 1, 50, 0.7) 100%),

);

$background-colors: (
  "primary": var(--color-lilac-150),
  "secondary":  map-get($gradients, 'blue'),
  "dialog":  rgba(#27125A, .75),
  "overlay": #000000b8,
  "tabbar": $color-text-primary,
  "bottom-sheet": linear-gradient(180deg, #261157 0%, rgba(38, 17, 87, 0.2) 75.43%),
  "button": rgba($color-text-primary, 0.6),
  "start": linear-gradient(180deg, #6800BA 44.1%, #300560 100%),
);

$text-colors: (
  "primary": $white-color,
  "secondary": var(--color-lilac-75),
  "disabled": var(--color-lilac-70),
  "inverted": var(--color-lilac-150),
  "negative": var(--color-red-90),
	"interactive-primary": var(--color-aquamarine-100),
	"interactive-secondary": var(--color-lilac-90),
  "button-link": var(--color-lilac-80),
);

$border-colors: (
  "default": #553D8D,
  "seleted": map-get($gradients, iridescent),
  "disabled": var(--color-gray-100),
  "negative": map-get($gradients, orange),
  "inactive": #AB9BFD26,
  "blue": #01FFC2,
);

$icon-colors: (
  "primary": $white-color,
  "secondary": var(--color-lilac-75),
  "disabled": var(--color-lilac-70),
  "inverted": var(--color-lilac-150),
  "interactive-primary": var(--color-aquamarine-100),
  "interactive-secondary": var(--color-lilac-90),
  "negative": var(--color-red-90)
);

$input-backgrounds: (
  "default": var(--color-eldeberry-70),
  "non-default": var(--color-lilac-150)
);

$button-backgrounds: (
  "primary": map-get($gradients, iridescent),
	"primary-hovered": linear-gradient(81.59deg,#AB38BE 0%,#2E1194 50.52%,#00E4AA 100%),
	"primary-disabled": var(--color-elderberry-70),
	"secondary": var(--color-lilac-150),
  "secondary-disabled": var(--color-elderberry-50),
  "negative": map-get($gradients, "orange"),
  "follow": rgba(var(--color-gray-110), 0.1),
  "logout": var(--color-lilac-135),
  "hovered": rgba(39, 18, 90, 0.3),
  "lilac-transparen-gradient": map-get($gradients, "lilac-transparent"),
  "blue": #01FFC2,
  "blue-hovered": #01c797,
);

$snackbar-colors: (
  "info": linear-gradient(315deg, #AB38BE 0%, #7A0EE5 100%),
  "success": linear-gradient(135deg, #22479A 0%, #03C493 100%),
  "error": linear-gradient(315.81deg, #E306C0 0%, #E61749 101.46%)
);

$password-strength-colors: (
  "strong": #00EE5F,
  "medium": #EACB00,
  "weak": #F87700,
	"error": var(--color-red-90)
);
