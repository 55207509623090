@import "functions";
@import "mixins";
@import "variables";

.cheelee-radio-btn {
	@include flexbox($ai: center, $jc: center);
	width: 24px;
	height: 24px;
	border-radius: 50%;
	border: 2px solid button-background("secondary-disabled");
	cursor: pointer;
	transition: .15s;
	
	&:active {
		filter: brightness(90%);
	}
	
	&.active {
		background: linear-gradient(131deg, #22479A 0%, #03C393 100%);
		border: none;
		
		&:after {
			content: "";
			width: 12px;
			height: 12px;
			border-radius: 50%;
			background-color: $white-color;
			animation: fade-in .1s ease-in forwards;
		}
	}
}
