.skeleton-shine {
	animation: shine .5s ease infinite alternate;
}

@keyframes shine {
	0% {
		filter: brightness(70%);
	}
	
	100% {
		filter: brightness(100%);
	}
}
