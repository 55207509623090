@import "variables";
@import "mixins";
@import "functions";

h1,h2,h3,h4,h5,h6,p {
  margin: 0;
}

.uppercase {
  text-transform: uppercase;
}

.bold {
  font-weight: 700;
  letter-spacing: 0.03em;
}

.headline {
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.06em;
}

.text-1 {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.28px;
  line-height: 16px;
}

.text-2 {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 1.6px;
}

.input-text {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.28px;
  
  &::placeholder {
    font-size: 14px;
    color: text-color("secondary");
    text-transform: none !important;
  }
}

.text-error {
  color: text-color("negative") !important;
}
