@keyframes shake {
	0% {
		transform: translateX(0);
	}
	25% {
		transform: translateX(5px);
	}
	50% {
		transform: translateX(-5px);
	}
	75% {
		transform: translateX(5px);
	}
	100% {
		transform: translateX(0);
	}
}

.shake-invalid {
	animation: shake 0.2s ease-in-out 0s 2;
}
