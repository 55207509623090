@import "mixins";

.fade-in-out {
	@include absolute-center();
	@include flexbox($ai: center, $jc: center);
	transform: unset;
	width: 30px;
	height: 30px;
	z-index: 20;

	background: rgba(#27125A, .6);
	border-radius: 26px;
	animation: fade-in-out .7s ease-in-out 1 normal forwards;
	pointer-events: none;
}

@keyframes fade-in-out {
	0% {
		opacity: 0;
	}
	
	20% {
		opacity: 1;
		-webkit-transform: scale(2.5);
		-o-transform: scale(2.5);
		transform: scale(2.5);
	}
	
	35% {
		opacity: 1;
		-webkit-transform: scale(2.2);
		-o-transform: scale(2.2);
		transform: scale(2.2);
	}
	
	80% {
		opacity: 1;
		-webkit-transform: scale(2.2);
		-o-transform: scale(2.2);
		transform: scale(2.2);
	}
	
	100% {
		opacity: 0;
		-webkit-transform: scale(1.8);
		-o-transform: scale(1.8);
		transform: scale(1.8);
	}
}

@keyframes fade-in {
	from {
		opacity: 0;
		transform: scale(.6);
	}
	
	to {
		opacity: 1;
		transform: scale(1);
	}
}

@keyframes fade-out {
	from {
		transform: scale(1);
		opacity: 1;
	}
	
	to {
		transform: scale(.4);
		opacity: 0;
	}
}
