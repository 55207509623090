@mixin ripple {
	border-radius: 50%;
	background-color: rgba(229, 229, 229, 0.5);
	position: absolute;
	transform: scale(0);
}

.ripple-effect {
	@include ripple();
	animation: ripple-animation 0.4s linear;
}

.ripple-effect-small {
	@include ripple();
	animation: ripple-animation-small 0.3s linear;
}

@keyframes ripple-animation {
	to {
		transform: scale(2.5);
		opacity: 0;
	}
}

@keyframes ripple-animation-small {
	to {
		transform: scale(0.9);
		opacity: 0;
	}
}
