@import "variables";
@import "mixins";

.start-screen__container {
  z-index: 100;
  pointer-events: none;
  transition: opacity .15s linear;

  img {
    animation: app-start .75s forwards;
  }
}

.start-screen {
  width: 100dvw;
  height: 100dvh;
  position: fixed;
  overflow-y: auto;

  &__container {
    position: relative;
    @include flexbox($ai: center, $jc: center);
    height: 100dvh;
    min-height: 740px;
    background: background-color("start");
    overflow: hidden;
  }

  &__logo {
    width: 249px;
    height: 141px;

    @media (min-width: $breakPointDesktopMin) {
      width: 264px;
      height: 150px;
    }
  }

  &__content {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    width: 448px;
    padding-bottom: 70px;
    text-align: center;
    word-wrap: break-word;
    white-space: pre-wrap;
    z-index: 1;

    & > *:not(.start-screen__logo) {
      display: none;
    }

    @media (min-width: $breakPointDesktopMin) {
      & > *:not(.start-screen__logo) {
        display: flex;
      }
    }
  }

  &__download-text {
    font-size: 28px;
    line-height: 33.6px;
    font-weight: 700;
    letter-spacing: 0.08em;
    text-align: center;
    text-transform: uppercase;
    min-height: 34px;

    &:first-of-type {
      margin-top: 30px;
    }

    &:last-of-type {
      font-size: 42px;
      font-weight: 900;
      line-height: 46.2px;
      text-transform: none;
      min-height: 92px;
    }
  }

  &__code {
    display: flex;
    margin-top: 30px;
  }

  &__code-img {
    display: block;
    margin: 0;
    padding: 0;
    width: 180px;
    height: 180px;
    border-radius: 12px;
    overflow: hidden;
    border: 4px solid #000000;
  }

  &__info {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 17px;
    margin-top: 30px;
    gap: 10px;
    border-radius: 16px;
    background: #330563CC;

    img {
      width: 40px;
      height: 40px;
      flex: 0 0 auto;
    }
    p {
      margin: 0;
      padding: 0;
      font-size: 16px;
      font-weight: 700;
      line-height: 19.2px;
      letter-spacing: 0.04em;
      text-align: left;
    }
  }
}


.start-screen__background {
  position: absolute;
  transform: rotate(0deg) scale(.5);

  & > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &--pyramid {
    width: 42vw;
    height: 42vw;
    top: 2.6%;
    left: -2.9%;
    transform: rotate(15.1deg);
    @media (min-width: $breakPointDesktopMin) {
      width: 15vw;
      height: 14vw;
      top: -0.7%;
      left: 2.0%;
      transform: rotate(16deg);
    }
  }
  &--paint-01 {
    width: 36vw;
    height: 52vw;
    top: 5.9%;
    left: -5.1%;
    transform: rotate(0);
    @media (min-width: $breakPointDesktopMin) {
      width: 16vw;
      height: 16vw;
      top: 9.9%;
      left: 1.2%;
      transform: rotate(0deg);
    }
  }
  &--spay-cyan-01 {
    width: 53vw;
    height: 60vw;
    top: .1%;
    left: -19.1%;
    transform: rotate(7.9deg);
    @media (min-width: $breakPointDesktopMin) {
      width: 20vw;
      height: 20.4vw;
      top: 1.9%;
      left: -5.3%;
      transform: rotate(0deg);
    }
  }
  &--spay-pink-01 {
    width: 52vw;
    height: 55vw;
    bottom: 17.1%;
    left: -35.9%;
    transform: rotate(138deg);
    @media (min-width: $breakPointDesktopMin) {
      width: 20vw;
      height: 20.4vw;
      top: 37.9%;
      left: -6.5%;
      bottom: auto;
      transform: rotate(0deg);
    }
  }
  &--spay-pink-02 {
    width: 60vw;
    height: 60vw;
    bottom: -2.5%;
    left: -8.6%;
    transform: rotate(53deg);
    @media (min-width: $breakPointDesktopMin) {
      width: 20vw;
      height: 20.4vw;
      bottom: 2.3%;
      left: 10.0%;
      transform: rotate(-120deg);
    }
  }
  &--paint-02 {
    width: 91vw;
    height: 67vw;
    bottom: -6.2%;
    left: -31.9%;
    transform: rotate(0);
    @media (min-width: $breakPointDesktopMin) {
      width: 28vw;
      height: 20vw;
      bottom: -3.1%;
      left: -8%;
      transform: rotate(0deg);
    }
  }
  &--bump {
    width: 30vw;
    height: 30vw;
    top: 21.8%;
    left: -9.5%;
    transform: rotate(0);
    @media (min-width: $breakPointDesktopMin) {
      width: 12vw;
      height: 12vw;
      top: 25.9%;
      left: 1.4%;
      transform: rotate(0);
    }
  }
  &--dollar {
    width: 20vw;
    height: 21vw;
    top: 1.2%;
    left: 25.5%;
    transform: rotate(23deg);
    @media (min-width: $breakPointDesktopMin) {
      width: 7vw;
      height: 8vw;
      top: 38.0%;
      left: 9.5%;
      transform: rotate(23deg);
    }
  }
  &--flower-pink {
    display: none;
    @media (min-width: $breakPointDesktopMin) {
      display: block;
      width: 10vw;
      height: 10vw;
      bottom: 38.2%;
      left: -2.3%;
      transform: rotate(0deg);
    }
  }
  &--moon {
    width: 42vw;
    height: 41vw;
    bottom: 3.8%;
    left: 2.9%;
    transform: rotate(0);
    @media (min-width: $breakPointDesktopMin) {
      width: 16.7vw;
      height: 16.7vw;
      bottom: 10.9%;
      left: 2.2%;
      transform: rotate(0deg);
    }
  }
  &--lee {
    width: 18vw;
    height: 18vw;
    bottom: 22.3%;
    left: -5.1%;
    transform: rotate(-25deg);
    @media (min-width: $breakPointDesktopMin) {
      width: 7vw;
      height: 7vw;
      bottom: 4.4%;
      left: 17.5%;
      transform: rotate(-25deg);
    }
  }
  &--spay-cyan-02 {
    width: 54vw;
    height: 54.5vw;
    top: 3.2%;
    right: -22.5%;
    transform: rotate(-34.5deg);
    @media (min-width: $breakPointDesktopMin) {
      width: 20vw;
      height: 21vw;
      top: 3.9%;
      right: -4.8%;
      transform: rotate(-75deg);
    }
  }
  &--spay-pink-03 {
    width: 54vw;
    height: 54.5vw;
    bottom: 20.7%;
    right: -39.5%;
    transform: rotate(-33deg);
    @media (min-width: $breakPointDesktopMin) {
      width: 20vw;
      height: 21vw;
      bottom: 14.1%;
      right: -5.8%;
      transform: rotate(0deg);
    }
  }
  &--spay-pink-04 {
    width: 63vw;
    height: 60vw;
    bottom: -3.1%;
    right: -31.5%;
    transform: rotate(14.5deg);
    @media (min-width: $breakPointDesktopMin) {
      width: 20vw;
      height: 21vw;
      bottom: -2.2%;
      right: 8.2%;
      transform: rotate(60deg);
    }
  }
  &--paint-03 {
    width: 85vw;
    height: 62vw;
    top: -3.5%;
    right: -32.1%;
    transform: rotate(13deg);
    @media (min-width: $breakPointDesktopMin) {
      width: 33vw;
      height: 22vw;
      top: -5.8%;
      right: -13.1%;
      transform: rotate(0deg);
    }
  }
  &--shuriken-black {
    display: none;
    @media (min-width: $breakPointDesktopMin) {
      display: block;
      width: 6vw;
      height: 6vw;
      bottom: 17.7%;
      right: 1.1%;
      transform: rotate(0deg);
    }
  }
  &--flower-blue-01 {
    width: 22vw;
    height: 22vw;
    bottom: 61.2%;
    right: -5.1%;
    transform: rotate(0);
    @media (min-width: $breakPointDesktopMin) {
      width: 9vw;
      height: 9vw;
      bottom: 31.5%;
      right: -3.2%;
      transform: rotate(0deg);
    }
  }
  &--flower-blue-02 {
    width: 22vw;
    height: 22vw;
    bottom: -2%;
    right: 36.1%;
    transform: rotate(0);
    @media (min-width: $breakPointDesktopMin) {
      display: none;
    }
  }
  &--crown {
    width: 14vw;
    height: 14vw;
    top: 8.5%;
    right: 37.9%;
    transform: rotate(15deg);
    @media (min-width: $breakPointDesktopMin) {
      width: 6.5vw;
      height: 6.5vw;
      top: 11.8%;
      right: 19.3%;
      transform: rotate(-30deg);
    }
  }
  &--cross {
    width: 56vw;
    height: 75vw;
    top: 0.6%;
    right: -13.8%;
    transform: rotate(-35.8deg);
    @media (min-width: $breakPointDesktopMin) {
      width: 20vw;
      height: 28vw;
      top: -1.6%;
      right: -2.1%;
      transform: rotate(-31deg);
    }
  }
  &--love {
    display: none;
    @media (min-width: $breakPointDesktopMin) {
      display: block;
      width: 15.5vw;
      height: 12.5vw;
      top: 29.1%;
      right: 5.1%;
      transform: rotate(0deg);
    }
  }
  &--hand {
    width: 27vw;
    height: 32vw;
    bottom: 16.0%;
    right: -2.7%;
    transform: rotate(0);
    @media (min-width: $breakPointDesktopMin) {
      width: 10vw;
      height: 10vw;
      bottom: 28.6%;
      right: 6.3%;
      transform: rotate(-14deg);
    }
  }
  &--heart {
    width: 36vw;
    height: 39vw;
    bottom: 0.5%;
    right: 5.1%;
    transform: rotate(24deg);
    @media (min-width: $breakPointDesktopMin) {
      width: 15vw;
      height: 16vw;
      bottom: 1%;
      right: 9.5%;
      transform: rotate(24deg);
    }
  }
}
