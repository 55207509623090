@import "mixins";

/* position */
.toast-center-center {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.toast-top-center {
	top: 0;
	right: 0;
	width: 100%;
}
.toast-bottom-center {
	bottom: 0;
	right: 0;
	width: 100%;
}
.toast-top-full-width {
	top: 0;
	right: 0;
	width: 100%;
}
.toast-bottom-full-width {
	bottom: 0;
	right: 0;
	width: 100%;
}
.toast-top-left {
	top: 12px;
	left: 12px;
}
.toast-top-right {
	top: 12px;
	right: 12px;
}
.toast-bottom-right {
	right: 12px;
	bottom: 12px;
}
.toast-bottom-left {
	bottom: 12px;
	left: 12px;
}
.toast-message a,
.toast-message label {
	color: #FFFFFF;
}
.toast-message a:hover {
	color: #CCCCCC;
	text-decoration: none;
}
.toast-close-button {
	position: relative;
	right: -0.3em;
	top: -0.3em;
	float: right;
	font-size: 20px;
	font-weight: bold;
	color: #FFFFFF;
	text-shadow: 0 1px 0 #ffffff;
	/* opacity: 0.8; */
}
.toast-close-button:hover,
.toast-close-button:focus {
	color: #000000;
	text-decoration: none;
	cursor: pointer;
	opacity: 0.4;
}
/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
	padding: 0;
	cursor: pointer;
	background: transparent;
	border: 0;
}
.toast-container {
	pointer-events: none;
	position: fixed;
	z-index: 999999;
	border-radius: 16px;
	padding: 8px 8px 0;
	margin-bottom: 8px;
	
	&:hover {
		filter: brightness(110%);
		transition: filter .1s;
		cursor: default;
	}
}
.toast-container * {
	box-sizing: border-box;
}
.toast-container .ngx-toastr {
	position: relative;
	overflow: hidden;
	width: 300px;
	border-radius: 16px;
}
.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
	width: 300px;
	margin-left: auto;
	margin-right: auto;
}
.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
	width: 100%;
}
.ngx-toastr {
	pointer-events: auto;
}
