@import "mixins";
@import "functions";

.cheelee-field {
	@include flexbox($ai: center);
	width: 100%;
	height: 52px;
	border-radius: 100px;
	padding: 18px 16px;
	background-color: var(--color-elderberry-70);
	cursor: pointer;
	
	&.disabled {
		cursor: not-allowed;
		pointer-events: none;
		background-color: var(--color-elderberry-80);
		color: var(--color-elderberry-40);
	}
}
