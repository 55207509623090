@import "variables";
@import "mixins";

.header {
  width: 100dvw;

  &--fixed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
  }
}

.header__logo {
  display: block;
  position: relative;
  width: 100px;
  height: 24px;
}

.header__container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: var(--header-height);
  padding: 12px;
}

.header__go-back {
  position: relative;
  margin-right: 1rem;
  z-index: 1;
}

.header__go-back-btn {
  border: none;
  background: transparent;
  cursor: pointer;
}

.header__go-back-icon {
  width: 24px;
  height: 24px;
}

.header__title {
  position: absolute;
  line-height: var(--header-height);
  text-align: center;
  top: 0;
  left: 0;
  width: 100%;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.1em;
  z-index: 0;
}

.header__right {
  position: relative;
  display: flex;
  align-items: center;
  z-index: 1;
}

.header__share-btn,
.header__settings-btn {
  border: none;
  background: transparent;
  cursor: pointer;
  margin-left: 1rem;
}

.header__share-icon,
.header__settings-icon {
  width: 24px;
  height: 24px;
}
