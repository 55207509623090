@import "variables";
@import "mixins";
@import "functions";

.layout {
  overflow: auto;
  width: 100dvw;
  height: 100dvh;
}

.container {
  display: block;
  max-width: 100dvw;
  margin: 0 auto;
  //padding: 0 $containerPaddingXMobile;

  @media (min-width: $breakPointTabletMin) {
    max-width: 1092px;
  }

  @media (min-width: $breakPointDesktopMin) {
    max-width: var(--feed-main-grid-width);
    //padding: 0 $containerPaddingXDesktop;
  }
}

.gradient-background {
  @include flexbox($jc: center);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(113, 86, 251, 0.06) 0%, rgba(113, 86, 251, 0.00) 100%);
  backdrop-filter: blur(45px);
  padding: 44px;
  z-index: 0;
  animation: fade-in .1s ease forwards;

  &__ellipse {
    content: "";
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    width: 66dvh;
    height: 100dvh;
    background: linear-gradient(68deg, #AB38BE -37.34%, #2E1194 49.31%, #00E4AA 108.31%);
    opacity: 0.3;
    z-index: -1;
  }
}

@media only screen and (min-device-width: 1000px) {
  ::-webkit-scrollbar {
    width: 16px;

    &-track {
      background-color: transparent;
    }

    &-thumb {
      background: rgba(180, 180, 180, 0.6);
      border-radius: 20px;
      border: 4px solid transparent;
      background-clip: padding-box;

      &:hover {
        background: gradient-color("violet");
        background-clip: padding-box;
      }
    }
  }
}

.content {
  position: relative;
  height: var(--feed-height-mobile);
  width: 100dvw;

  & > .main-background {
    background-image: image-set(
        url("~src/assets/img/main-bg.png") 1x,
        url("~src/assets/img/main-bg-2x.png") 2x
    );
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: top center;
    background-color: #1A0736;
  }

  @include breakpoint(desktop) {
    height: 100%;
    width: 100%;
  }

  &__loader {
    @include absolute-fill();
    @include flexbox($ai: center);
    height: 100%;
    //padding-bottom: $tab-bar-height;
  }
}
