@import "mixins";
@import "variables";

.cheelee-text-area {
	resize: none;
	background: var(--color-elderberry-70);
	border-radius: 20px;
	min-height: 80px;
	transition: .15s;
	padding: 8px 16px;
	width: 100%;
	border: none;
	color: $white-color;
	
	&:focus-within {
		background: transparent;
		@include cheelee-gradient-border($radius: 20px);
	}
}
